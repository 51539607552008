<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="itemHeading1">Edit Rescuer</span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start pt-3>
                  <v-flex xs12>
                    <span class="heading1">Basic Info</span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-2>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Username</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="username"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Name</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="name"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>ID Type</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="idType"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Rescuer ID</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="idNumber"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Rescuer Code</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="rescuerCode"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 md6 lg6 sm6 class="subheading" pl-lg-2 pl-md-2 pl-sm-2>
                    <span>Employment</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="employment"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Email</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="email"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Phone No</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="phone"
                      type="number"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Organization</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="organization"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Aadhar Number</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="adharnumber"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Blood Group</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="bloodGroup"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>District</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="district"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Education</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="education"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Rescue Statred at (in years)</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="rescueStartedAt"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Permanent Address</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="permanentAddress"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Present Address</span>
                    <v-text-field
                      outlined
                      dense
                      v-model="presentAddress"
                      hide-details="true"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Disease Status</span>
                    <v-select
                      :items="diseaseStatusitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="diseaseStatus"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Is Rescuer</span>
                    <v-select
                      :items="isrescueravailableitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="is_rescuer"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Is Snake Expert?</span>
                    <v-select
                      :items="issnakeexpertitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="issnakeexpert"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                    lg6
                    sm6
                    class="subheading"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>Is Rescuer Available?</span>
                    <v-select
                      :items="isrescueravailableitems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="is_rescuer_Available"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start pt-4>
                  <v-flex xs12 md6 lg6 sm6 class="subheading">
                    <span>Is District Coordinator</span>
                    <v-select
                      :items="districtCoordinatoritems"
                      outlined
                      dense
                      hide-details="true"
                      v-model="districtCoordinator"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end pt-4>
                  <v-flex xs12 sm6 md6 lg6 text-end>
                    <v-btn depressed color="#FF6907" @click="handlesubmit()">
                      <span style="color:white">Update User Details</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center pt-5>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex xs12>
                        <span class="heading1">Change Password</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap justify-start pt-4>
                      <v-flex xs12>
                        <span class="subheading">Change Password</span>
                        <v-text-field
                          outlined
                          dense
                          hide-details="true"
                          v-model="password"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end pt-4>
                      <v-flex text-end xs12 sm6 md6 lg6>
                        <v-btn
                          depressed
                          color="#FF6907"
                          @click="updatepassword()"
                        >
                          <span style="color:white">Update Password</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      issnakeexpertitems: ["Yes", "No"],
      isrescueravailableitems: ["Yes", "No"],
      isrescueritems: ["Yes", "No"],
      diseaseStatusitems: ["Yes", "No"],
      diseaseStatus: "",
      districtCoordinator: "",
      districtCoordinatoritems: ["Yes", "No"],
      username: "",
      name: "",
      phone: "",
      idType: "",
      idNumber: "",
      email: "",
      error: "",
      organization: "",
      is_rescuer: "",
      issnakeexpert: "",
      is_rescuer_Available: "",
      password: "",
      rescuerCode: "",
      adharnumber: "",
      bloodGroup: "",
      district: "",
      education: "",
      rescueStartedAt: "",
      permanentAddress: "",
      presentAddress: "",
      employment: "",
      resid:"",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    openEditDialog(item) {
      this.$router.push({
        path: "/edituser",
        query: {
          id: item._id,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.username = response.data.data.username;
              this.name = response.data.data.name;
              this.email = response.data.data.email;
              this.phone = response.data.data.phone;
              this.idType = response.data.rescuer.idType;
              this.rescuerCode = response.data.rescuer.rescuerCode;
              this.idNumber = response.data.rescuer.idNumber;
              this.organization = response.data.data.organization;
              this.district = response.data.rescuer.district;
              this.is_rescuer = response.data.data.is_rescuer;
              this.issnakeexpert = response.data.data.issnakeexpert;
              this.education = response.data.rescuer.education;
              this.permanentAddress = response.data.rescuer.permanentAddress;
              this.presentAddress = response.data.rescuer.presentAddress;
              this.rescueStartedAt = response.data.rescuer.rescueStartedAt;
              this.rescuerCode = response.data.rescuer.rescuerCode;
              this.adharnumber = response.data.rescuer.adharnumber;
              this.bloodGroup = response.data.rescuer.bloodGroup;
              this.diseaseStatus = response.data.rescuer.diseaseStatus;
              this.employment = response.data.rescuer.employment;
              this.resid = response.data.rescuer._id;
              this.districtCoordinator =
                response.data.rescuer.districtCoordinator;

              this.is_rescuer_Available =
                response.data.data.is_rescuer_Available;
              if (this.is_rescuer === true) {
                this.is_rescuer = "Yes";
              }
              if (this.is_rescuer === false) {
                this.is_rescuer = "No";
              }
              if (this.is_rescuer_Available === true) {
                this.is_rescuer_Available = "Yes";
              }
              if (this.is_rescuer_Available === false) {
                this.is_rescuer_Available = "No";
              }
              if (this.issnakeexpert === true) {
                this.issnakeexpert = "Yes";
              }
              if (this.issnakeexpert === false) {
                this.issnakeexpert = "No";
              }
              if (this.districtCoordinator === true) {
                this.districtCoordinator = "Yes";
              }
              if (this.districtCoordinator === false) {
                this.districtCoordinator = "No";
              }
              if (this.diseaseStatus === true) {
                this.diseaseStatus = "Yes";
              }
              if (this.diseaseStatus === false) {
                this.diseaseStatus = "No";
              }
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        this.msg = "Please enter a valid email address.";
        this.showSnackBar = true;
        return;
      }
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(this.phone)) {
        this.msg = "Please enter a valid 10-digit phone number.";
        this.showSnackBar = true;
        return;
      }

      if (this.is_rescuer === "Yes") {
        this.isrescueredit = true;
      }
      if (this.is_rescuer === "No") {
        this.isrescueredit = false;
      }
      if (this.is_rescuer_Available === "Yes") {
        this.isrescuerAvailableedit = true;
      }
      if (this.is_rescuer_Available === "No") {
        this.isrescuerAvailableedit = false;
      }
      if (this.issnakeexpert === "Yes") {
        this.issnakeexpertedit = true;
      }
      if (this.issnakeexpert === "No") {
        this.issnakeexpertedit = false;
      }
      if (this.diseaseStatus === "Yes") {
        this.diseaseStatusedit = true;
      }
      if (this.diseaseStatus === "No") {
        this.diseaseStatusedit = false;
      }
      if (this.districtCoordinator === "Yes") {
        this.districtCoordinatoredit = true;
      }
      if (this.districtCoordinator === "No") {
        this.districtCoordinatoredit = false;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/adminrescueredit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.resid,
          name: this.name,
          username: this.username,
          email: this.email,
          phone: this.phone,
          bloodGroup: this.bloodGroup,
          adharnumber: this.adharnumber,
          district: this.district,
          education: this.education,
          employment: this.employment,
          idNumber: this.idNumber,
          idType: this.idType,
          permanentAddress: this.permanentAddress,
          presentAddress: this.presentAddress,
          rescueStartedAt: this.rescueStartedAt,
          rescuerCode: this.rescuerCode, 
          organization: this.organization,

          districtCoordinator: this.districtCoordinatoredit, 
          diseaseStatus: this.diseaseStatusedit,
          is_rescuer: this.isrescueredit,
          is_rescuer_Available: this.isrescuerAvailableedit,
          issnakeexpert: this.issnakeexpertedit,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.$router.push({
                path: "/allrescuerlist",
                query: {
                 
                  msg: response.data.msg,
                },
              });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    updatepassword() {
      if(!this.password){
        this.msg = "Please enter password to update.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/editpassword",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          password: this.password,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Password Updated Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
  <style>

.subheading {
  font-family: sofiaProRegular;
  font-size: 16px;
  font-weight: 500;
}
</style>